/**
 * Tracks active browser sessions and triggers callbacks on session changes.
 * A session ends when:
 * - The window loses focus
 * - The window becomes hidden
 * - User is inactive for the specified idle time
 *
 * @param {Object} params - Configuration parameters
 * @param {Function} params.onEnter - Callback function called when a new session starts
 * @param {Function} params.onLeave - Callback function called when a session ends
 * @param {number} params.onLeave.sessionDuration - Duration of the just-ended session in milliseconds
 * @param {number} params.onLeave.totalActiveTime - Total active time across all sessions in milliseconds
 * @param {number} [params.idleTime=60] - Time in seconds after which user is considered idle
 * @param {number} [params.checkInterval=1] - Interval in seconds to check for idle state
 * @returns {Function} Cleanup function that removes all event listeners when called
 *
 * @example
 * const cleanup = trackActiveTimeSpent({
 *   onEnter: () => console.log('Session started'),
 *   onLeave: (duration, total) => console.log(`Session ended after ${duration}ms. Total: ${total}ms`),
 *   idleTime: 60, // optional: seconds until considered idle
 *   checkInterval: 1 // optional: check interval in seconds
 * });
 *
 * // Later: cleanup();
 */
interface Params {
  onEnter: () => void;
  onLeave: (sessionDuration: number, totalActiveTime: number) => void;
  idleTime?: number; // seconds until considered idle
  checkInterval?: number; // seconds between checks
}

export const trackActiveTimeSpent = ({
  onEnter,
  onLeave,
  idleTime = 30,
  checkInterval = 1,
}: Params) => {
  onEnter();
  let sessionStartTime: number | null = performance.now();
  let totalActiveTime = 0;
  let lastActivityTime = performance.now();
  let isIdle = false;
  let idleCheckInterval: number | null = null;

  const ACTIVITY_EVENTS = [
    "mousedown",
    "mousemove",
    "keydown",
    "scroll",
    "touchstart",
  ];

  const handleEnter = () => {
    if (sessionStartTime !== null) {
      return;
    }
    sessionStartTime = performance.now();
    onEnter?.();
  };

  const handleLeave = () => {
    if (sessionStartTime === null) {
      return;
    }
    const sessionDuration = performance.now() - sessionStartTime;
    sessionStartTime = null;
    totalActiveTime += sessionDuration;
    onLeave?.(sessionDuration, totalActiveTime);
  };

  // Activity tracking
  const handleActivity = () => {
    lastActivityTime = performance.now();
    if (isIdle) {
      isIdle = false;
      handleEnter();
    }
  };

  // Idle checking
  const checkIdle = () => {
    if (sessionStartTime === null || isIdle) {
      return;
    }

    const timeSinceLastActivity = performance.now() - lastActivityTime;
    if (timeSinceLastActivity > idleTime * 1000) {
      isIdle = true;
      handleLeave();
    }
  };

  // Initialize on creation
  sessionStartTime = performance.now();
  lastActivityTime = performance.now();

  // Set up event listeners
  const blurHandler = () => handleLeave();
  const focusHandler = () => handleEnter();
  const visibilityHandler = () => {
    if (document.hidden) {
      handleLeave();
    } else {
      handleEnter();
    }
  };
  const unloadHandler = () => handleLeave();

  // Attach listeners
  window.addEventListener("blur", blurHandler);
  window.addEventListener("focus", focusHandler);
  document.addEventListener("visibilitychange", visibilityHandler);
  window.addEventListener("beforeunload", unloadHandler);

  // Attach activity listeners
  ACTIVITY_EVENTS.forEach((event) => {
    window.addEventListener(event, handleActivity);
  });

  // Start idle checking interval
  idleCheckInterval = window.setInterval(checkIdle, checkInterval * 1000);

  // Return unsubscribe function
  return () => {
    window.removeEventListener("blur", blurHandler);
    window.removeEventListener("focus", focusHandler);
    document.removeEventListener("visibilitychange", visibilityHandler);
    window.removeEventListener("beforeunload", unloadHandler);

    ACTIVITY_EVENTS.forEach((event) => {
      window.removeEventListener(event, handleActivity);
    });

    if (idleCheckInterval) {
      clearInterval(idleCheckInterval);
    }
  };
};
