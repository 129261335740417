import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import { Theme } from '../theme';
import { ProgressSize, ProgressStatus, ProgressColorValue } from './types';

const widthTransition = 'width .2s ease-in';

interface borderRadiusMixingProps {
  theme: Theme;
  value?: string | number | readonly string[];
  max?: string | number | undefined;
}

const borderRadiusMixin = ({ theme, value, max }: borderRadiusMixingProps) => {
  if (value === max) {
    return css`border-radius: ${theme.progressIndicator.borderRadius};`;
  }
  return css`
    /*! @noflip */ border-radius: ${theme.progressIndicator.borderRadius} 0 0 ${theme.progressIndicator.borderRadius};
  `;
};

/** Separated out due to issues with styled-components not setting rtl styles properly */
const borderRadiusRTLMixin = ({ theme, value, max }: borderRadiusMixingProps) => {
  if (value === max) {
    return css`border-radius: ${theme.progressIndicator.borderRadius};`;
  }
  return css`
    /*! @noflip */ border-radius: 0 ${theme.progressIndicator.borderRadius} ${theme.progressIndicator.borderRadius} 0;
  `;
};

const getColorFromTheme = ({
  theme,
  $status,
  $colorValue,
}: { theme: Theme, $status: ProgressStatus, $colorValue: ProgressColorValue }) => {
  if ($status === 'error') {
    return theme.progressIndicator.colors.error[$colorValue];
  }
  if ($status === 'warning') {
    return theme.progressIndicator.colors.warning[$colorValue];
  }
  return theme.progressIndicator.colors.default[$colorValue];
};

export interface ProgressContainerProps {
  $inline?: boolean;
}
export const ProgressContainer = styled('div') <ProgressContainerProps>`
  ${({ $inline }) => ($inline ? css`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  ` : css`
    display: block;
  `)};
  width: 100%;
`;

export interface TickProps extends MergeElementProps<'div'> {
  $size: ProgressSize;
  $targetValue: number;
  $value: number;
}

export const Tick = styled('div') <TickProps>`
  position: absolute;
  width: 1px;
  height: ${({ $size, theme }) => ($size === 'small' ? theme.progressIndicator.tickHeightSmall : theme.progressIndicator.tickHeightMini)};
  background: ${({ theme }) => theme.progressIndicator.tickBg};

  /* @noflip */ left: ${({ $targetValue }) => $targetValue}%;
  inset-inline-start: ${({ $targetValue }) => $targetValue}%;
  /* @noflip */ right: auto;
  inset-inline-end: auto;

  [dir="rtl"] && {
    /* @noflip */ right: ${({ $targetValue }) => $targetValue}%;
    inset-inline-start ${({ $targetValue }) => $targetValue}%;
    /* @noflip */ left: auto;
    inset-inline-end: auto;
  }

  ${({
    $size,
    $targetValue,
    $value,
    theme,
  }) => ($value > $targetValue) && css`
    ::before {
      content: "";
      background: ${theme.progressIndicator.bgDefault};
      height: ${($size === 'small' ? theme.progressIndicator.heightSmall : theme.progressIndicator.heightMini)};
      position: absolute;
      right: 1px;
      top: 2px;
      width: 2px;
    }

    ::after {
      content: "";
      background: ${theme.progressIndicator.bgDefault};
      height: ${($size === 'small' ? theme.progressIndicator.heightSmall : theme.progressIndicator.heightMini)};
      left: 1px;
      top: 2px;
      position: absolute;
      width: 2px;
    }
  `}
`;

export interface ProgressProps extends MergeElementProps<'progress'> {
  $inline?: boolean;
  $size: ProgressSize;
  $status: ProgressStatus;
  $colorValue: ProgressColorValue;
}

// Note: Firefox does not give a means of styling the <progress> container.
// Currently only webkit supports animations on the progress bar itself.
export const Progress = styled('progress') <ProgressProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-color: ${({ theme }) => theme.progressIndicator.bgDefault};
  border: none;
  border-radius: ${({ theme }) => theme.progressIndicator.borderRadius};
  color: ${getColorFromTheme};

  height: ${({ theme, $size }) => ($size === 'small' ? theme.progressIndicator.heightSmall : theme.progressIndicator.heightMini)};
  width: 100%;

  ::-webkit-progress-bar {
    background: ${({ theme }) => theme.progressIndicator.bgDefault};
    border-radius: ${({ theme }) => theme.progressIndicator.borderRadius};
    @media (prefers-reduced-motion: no-preference) {
      transition: ${widthTransition};
    }
  }

  ::-webkit-progress-value {
    background: ${getColorFromTheme};
    ${borderRadiusMixin}
    @media (prefers-reduced-motion: no-preference) {
      transition: ${widthTransition};
    }
  }

  ::-moz-progress-bar {
    background: ${getColorFromTheme};
    ${borderRadiusMixin}
    @media (prefers-reduced-motion: no-preference) {
      transition: ${widthTransition};
    }
  }

  /*! @noflip */
  [dir="rtl"] & {
    ::-webkit-progress-value {
      ${borderRadiusRTLMixin}
    }
    ::-moz-progress-bar {
      ${borderRadiusRTLMixin}
    }
  }
`;

export const Container = styled('div')`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;

  & > ${Progress}:not(:first-child) {
    position: absolute;
    background-color: transparent;

    ::-webkit-progress-bar {
      background: transparent;
    }
  }
`;
