import React, {
  forwardRef,
  ReactNode,
  ComponentPropsWithoutRef,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  check,
  exclamation,
  exclamationTriangle,
  infoCircle,
  spinner,
  times,
} from '@amzn/storm-ui-icons';
import Icon from '../Icon';
import Text from '../Text';
import { TextButton } from '../Button';
import useIsMobile from '../theme/MobileThemeProvider/useIsMobile';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

export interface SnackbarContainerProps {
  dismissable: boolean;
}

const SnackbarContainer = styled('div') <SnackbarContainerProps>`
  background-color: ${({ theme }) => theme.snackbar.backgroundColor};
  border: ${({ theme }) => theme.snackbar.borderWidth} solid ${({ theme }) => theme.snackbar.borderColor};
  border-radius: ${({ theme }) => theme.snackbar.borderRadius};
  box-shadow: ${({ theme }) => theme.snackbar.boxShadow};
  max-width: 450px;
  padding: ${({ theme }) => theme.snackbar.padding};
  ${({ dismissable }) => dismissable && css`padding-bottom: 14px;`};
  ${isMobile(css`
    max-width: 100%;
    padding-bottom: 18px;
  `)}
`;

const ActionContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
const TextContainer = styled('div')`
  flex-grow: 1;
`;

const CloseIconButton = styled(TextButton)`
  color: ${({ theme }) => theme.snackbar.closeButton.color};
  :hover, :active {
    color: ${({ theme }) => theme.snackbar.closeButton.hoverColor};
  }
  font-size: 16px; /* comes out to 12x12 visually with FontAwesome */
  /* invert theme.snackbar.padding for a proper touch target */
  margin-block-start: -18px;
  margin-block-end: -18px;
  margin-inline-start: 0;
  margin-inline-end: -14px;
  padding-block-start: 18px;
  padding-block-end: 18px;
  padding-inline-start: 14px;
  padding-inline-end: 14px;
  /* align to first line of message */
  align-self: flex-start;
`;

const IconContainer = styled('div')`
  flex: 0 1;
  margin-inline-end: 10px;
`;

const MessageContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const InfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.message.info.iconColor};
`;
InfoIcon.displayName = 'InfoIcon';

const WarningIcon = styled(Icon)`
  color: ${({ theme }) => theme.message.warning.iconColor};
`;
WarningIcon.displayName = 'WarningIcon';

const SuccessIcon = styled(Icon)`
  color: ${({ theme }) => theme.message.success.iconColor};
`;
SuccessIcon.displayName = 'SuccessIcon';

const ErrorIcon = styled(Icon)`
  color: ${({ theme }) => theme.message.error.iconColor};
`;
ErrorIcon.displayName = 'ErrorIcon';

export interface SnackbarProps extends ComponentPropsWithoutRef<typeof SnackbarContainer> {
  /**
   * The content of the snackbar.
   */
  children: ReactNode;
  /**
   * A unique identifier for the snackbar.
   */
  id: string;
  /**
   * Displays the type of snackbar: leaving undefined will not show an icon at all.
   * @defaultValue `undefined`
   */
  type?: 'success' | 'error' | 'warning' | 'info' | 'loading';
  /**
   * Callback that is called when the dismiss button is clicked.
   * @defaultValue `undefined`
   */
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Text that appears in the close button. Use this prop to localize the close button.
   * @defaultValue `"Dismiss"`
   */
  closeButtonLabel?: string;
}

const Snackbar = forwardRef<
  HTMLDivElement, SnackbarProps
>((props, ref) => {
  const {
    children,
    closeButtonLabel,
    id,
    onClose,
    type,
    ...rest
  } = props;

  return (
    <>
      {useIsMobile()
        ? (
          <SnackbarContainer
            dismissable={!!onClose}
            id={id}
            ref={ref}
            role={onClose ? 'alertdialog' : 'alert'}
            {...rest}
          >
            <MessageContainer>
              {(type !== undefined && (
                <IconContainer>
                  {(type === 'error') && <ErrorIcon type={exclamation} size="lg" />}
                  {(type === 'info') && <InfoIcon type={infoCircle} size="lg" />}
                  {(type === 'warning') && <WarningIcon type={exclamationTriangle} size="lg" />}
                  {(type === 'success') && <SuccessIcon type={check} size="lg" />}
                  {(type === 'loading') && <Icon type={spinner} size="lg" />}
                </IconContainer>
              ))}
              <TextContainer>
                <Text type="span">
                  {children}
                </Text>
              </TextContainer>
              {onClose && (
                <CloseIconButton id={`${id}-dismiss-button-mobile`} onClick={onClose}>
                  <Text type="sr-only">
                    {closeButtonLabel}
                  </Text>
                  <Icon type={times} />
                </CloseIconButton>
              )}
            </MessageContainer>
          </SnackbarContainer>
        ) : (
          <SnackbarContainer
            dismissable={!!onClose}
            id={id}
            ref={ref}
            role={onClose ? 'alertdialog' : 'alert'}
            {...rest}
          >
            <MessageContainer>
              {(type !== undefined && (
                <IconContainer>
                  {(type === 'error') && <ErrorIcon type={exclamation} size="lg" />}
                  {(type === 'info') && <InfoIcon type={infoCircle} size="lg" />}
                  {(type === 'warning') && <WarningIcon type={exclamationTriangle} size="lg" />}
                  {(type === 'success') && <SuccessIcon type={check} size="lg" />}
                  {(type === 'loading') && <Icon type={spinner} size="lg" />}
                </IconContainer>
              ))}
              <Text type="span">
                {children}
              </Text>
            </MessageContainer>
            {onClose && (
              <ActionContainer>
                <TextButton id={`${id}-dismiss-button`} onClick={onClose}>{closeButtonLabel}</TextButton>
              </ActionContainer>
            )}
          </SnackbarContainer>
        )}
    </>
  );
});

Snackbar.propTypes = {
  /**
   * The content of the snackbar.
   */
  children: PropTypes.node.isRequired,
  /**
   * A unique identifier for the snackbar.
   */
  id: PropTypes.string.isRequired,
  /**
   * Displays the type of snackbar: leaving undefined will not show an icon at all.
   */
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info', 'loading']),
  /**
   * Callback that is called when the dismiss button is clicked.
   */
  onClose: PropTypes.func,
  /**
   * Text that appears in the close button. Use this prop to localize the close button.
   */
  closeButtonLabel: PropTypes.string,
};

Snackbar.defaultProps = {
  type: undefined,
  onClose: undefined,
  closeButtonLabel: 'Dismiss',
};

export default Snackbar;
