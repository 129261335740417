/* eslint-disable import/prefer-default-export */
import { getPadding } from '@amzn/storm-ui-utils';
import styled, { css } from 'styled-components';
import Box from '../Box/Box';
import { PaddingOptions } from '../types/PaddingOptions';
import type {
  AlignContent,
  AlignItems,
  FlexDirection,
  JustifyContent,
  FlexWrap,
  FlexGrow,
  FlexShrink,
  Order,
  FlexBasis,
} from '../types/CssProps';

interface FlexBoxProps {
  /**
   * Used to align the children along the cross axis.
   */
  $alignContent?: AlignContent;
  /**
   * Used to align the children along the cross axis. Sets the
   * `align-self` value on all direct children as a group.
   */
  $alignItems?: AlignItems;
  /**
   * Represents the flex direction property of CSS flexbox.
   */
  $direction?: FlexDirection;
  /**
   * Used to align the children along the main axis.
   */
  $justifyContent?: JustifyContent;
  /**
   * Represents the space between each child.
   */
  $columnGap?: PaddingOptions;
  /**
   * Represents the space between each child.
   */
  $rowGap?: PaddingOptions;
  /**
   * Represents the flex wrap property of CSS flexbox.
   */
  $wrap?: FlexWrap;
  /**
   * Represents the flex grow property of CSS flexbox.
   */
  $grow?: FlexGrow;
  /**
   * Represents the flex shrink property of CSS flexbox.
   */
  $shrink?: FlexShrink;
  /**
   * Sets the order to lay out an item in a flex or grid container.
   */
  $order?: Order;
  /**
   * Represents the flex basis property of CSS flexbox.
   */
  $basis?: FlexBasis;
}

export const FlexBox = styled(Box)<FlexBoxProps>`
  display: flex;
  border-radius: unset;
  ${({ $direction }) => css`flex-direction: ${$direction};`}
  ${({ $alignContent }) => css`align-content: ${$alignContent};`}
  ${({ $justifyContent }) => css`justify-content: ${$justifyContent};`}
  ${({ $alignItems }) => css`align-items: ${$alignItems};`}
  ${({ theme, $columnGap }) => css`column-gap: ${getPadding(theme, $columnGap)}px;`}
  ${({ theme, $rowGap }) => css`row-gap: ${getPadding(theme, $rowGap)}px;`}
  ${({ $wrap }) => css`flex-wrap: ${$wrap};`}
  ${({ $shrink }) => css`flex-shrink: ${$shrink};`}
  ${({ $grow }) => css`flex-grow: ${$grow};`}
  ${({ $basis }) => css`flex-basis: ${$basis};`}
`;
