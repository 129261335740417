import { COUNTERS, OPS_METRICS_TYPES, TIMERS } from "../constants";
import { TelemetryClientInterface } from "../types";
import { trackActiveTimeSpent } from "../utilities/track-active-time-spent";
/**
 * Captures time spent actively using the current tab.
 * A timer metric is emitted whenever the tab loses focus and started again when the tab is re-focused
 */
export const monitorActiveTimeSpent = (
  telemetryClient: TelemetryClientInterface
) => {
  const unsub = trackActiveTimeSpent({
    onEnter: () => {
      telemetryClient.startTimer(TIMERS.ActiveTimeSpent);
      telemetryClient.publishCounter(
        COUNTERS.UserActiveStart,
        OPS_METRICS_TYPES.Count,
        1
      );
    },
    onLeave: () => {
      telemetryClient.stopTimer(TIMERS.ActiveTimeSpent);
    },
  });
  return unsub;
};
