import styled, { css } from 'styled-components';
import { deviceTypes, getPadding } from '@amzn/storm-ui-utils';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { BoxBgColors, BoxPadding } from './types';
import { defaultTheme } from '../theme';
import {
  Flex,
  FlexBasis,
  FlexGrow,
  FlexShrink,
  Order,
} from '../types/CssProps';

interface BoxStyledProps {
  $flex?: Flex;
  $basis?: FlexBasis;
  $grow?: FlexGrow;
  $shrink?: FlexShrink;
  $order?: Order;
  $paddingBlockStart?: BoxPadding;
  $paddingBlockEnd?: BoxPadding;
  $paddingInlineStart?: BoxPadding;
  $paddingInlineEnd?: BoxPadding;
  $bgColor?: BoxBgColors
}

const getBgColor = (color: BoxBgColors): string => {
  if (defaultTheme
    && defaultTheme.pageLayout
    && typeof defaultTheme.pageLayout.section.background[color] !== 'undefined'
  ) {
    return defaultTheme.pageLayout.section.background[color];
  }
  return 'none';
}

const BoxStyled = styled('div')<BoxStyledProps>`
  border-radius:  ${({ theme }) => theme.card.radius};
  ${({ $bgColor }) => $bgColor && css`background: ${getBgColor($bgColor)};`}

  box-sizing: border-box;
  padding-block-start: ${({ theme, $paddingBlockStart }) => getPadding(theme, $paddingBlockStart)}px;
  padding-block-end: ${({ theme, $paddingBlockEnd }) => getPadding(theme, $paddingBlockEnd)}px;
  padding-inline-start: ${({ theme, $paddingInlineStart }) => getPadding(theme, $paddingInlineStart)}px;
  padding-inline-end: ${({ theme, $paddingInlineEnd }) => getPadding(theme, $paddingInlineEnd)}px;

  ${({ $flex }) => css`flex: ${$flex};`}
  ${({ $basis }) => css`flex-basis: ${$basis};`}
  ${({ $grow }) => css`flex-grow: ${$grow};`}
  ${({ $shrink }) => css`flex-shrink: ${$shrink};`}
  ${({ $order }) => css`order: ${$order};`}

  ${isMobile(css<BoxStyledProps>`
    padding-block-start: ${({ theme, $paddingBlockStart }) => getPadding(theme, $paddingBlockStart, deviceTypes.MOBILE)}px;
    padding-block-end: ${({ theme, $paddingBlockEnd }) => getPadding(theme, $paddingBlockEnd, deviceTypes.MOBILE)}px;
    padding-inline-start: ${({ theme, $paddingInlineStart }) => getPadding(theme, $paddingInlineStart, deviceTypes.MOBILE)}px;
    padding-inline-end: ${({ theme, $paddingInlineEnd }) => getPadding(theme, $paddingInlineEnd, deviceTypes.MOBILE)}px;
  `)}
`;

export default BoxStyled;
